// Improving UX after fitment's request using MyGarage component
export const isLoading = (status: boolean) => {
  const elements = [
    ...document.querySelectorAll('button'),
    ...document.querySelectorAll('span'),
  ];

  const cursorMap = {
    BUTTON: 'pointer',
    default: 'pointer',
  };

  elements.forEach((element) => {
    element.style.cursor = status
      ? 'wait'
      : cursorMap[element.tagName] || cursorMap.default;
  });
  document.body.style.cursor = status ? 'wait' : 'auto';
};

export const formatObjectKeysToUpperCase = (obj) => {
  const formattedObj = {};

  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      const formattedKey = capitalizeFirstChar(key);
      formattedObj[formattedKey] = obj[key];
    }
  }

  return formattedObj;
};

export const capitalizeFirstChar = (val) => {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
};

export const isObjectInArray = (obj, arr) => {
  return arr?.some((item) => {
    return (
      Object.keys(obj).length === Object.keys(item).length &&
      Object.keys(obj).every((key) => {
        return item[key] === obj[key];
      })
    );
  });
};
