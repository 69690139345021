// The key of the fitment selectors stored in local storage.
export const FITMENT_SELECTOR_STORAGE_KEY = 'sui-fitment-selector-v2';
export const HISTORIC_FITMENT_STORAGE_KEY = 'sui-historic-fitment';
export const HISTORIC_FITMENT_LIMIT_LABEL = 'historic-fitment-limit';

export const OPTIONAL_QUALIFIERS_STORAGE_KEY = 'optional-qualifiers';
export const OPTIONAL_QUALIFIERS_FOR_URL_STORAGE_KEY =
  'optional-qualifiers-for-url';
export const DYN_SELECTED_VQ = 'dyn-selectedvq';
export const DYN_SELECTED_VQ_REALID = 'dyn-selectedvq-realid';
// Max numbers of Fitments to be stored in the Database
export const LIMIT_FITMENTS_LOCAL_STORAGE = 10;

export const SEARCH_TERMS_HISTORY_KEY = 'pl-recent-search-terms';
