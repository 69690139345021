import { env } from '../config';

export const PRODUCTS_URL = `${env.API_URL}/products`;
export const FACET_FILTERS_URL = `${env.API_URL}/attributes`;
export const CATEGORIES_URL = `${env.API_URL}/categories`;

export function getProductsQuery(
  page: number,
  itemsPerPage: number,
  search: string,
  sort?: string | number,
  fitment?: string,
  productId?: number | string,
  uid?: string
) {
  const entity = localStorage.getItem('entity') || '';
  return {
    limit: itemsPerPage,
    page,
    q: search,
    uid,
    ...(sort ? { sort } : {}),
    ...(fitment ? { fitment } : {}),
    ...(entity ? { segmentId: entity } : {}),
    ...(productId ? { productId } : {}),
    ...(productId ? { getSkus: true } : {}),
  };
}
