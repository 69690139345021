import classnames from 'classnames';
import * as React from 'react';
import {
  FitmentSelectorProps,
  FitmentLabelEntity,
  SelectedValues,
} from '../FitmentSelector/models';
import FitmentSelectorWrapper from '../FitmentSelectorWrapper';
import { FitmentSelectorWrapperProps } from '../FitmentSelectorWrapper/models';
import styles from './styles/ParslogicFitmentSelectorWrapper.scss';
import { getExistingFitment } from '../../utils/fitmentUtils';

interface PartslogicProductListWrapperProps
  extends Omit<FitmentSelectorWrapperProps, 'onSubmit'> {
  onSubmit: (fitment: string, opts: any) => void;
  selectedVinFitment: SelectedValues;
}

const ParslogicFitmentSelectorWrapper = ({
  autocommitDelay = 2000,
  className = '',
  clearButtonText = 'Clear',
  filter = {},
  orientation = 'horizontal',
  searchButtonText = 'Search',
  selectedValues: initailSelectedValues = null,
  styled = true,
  selectedVinFitment,
  onSubmit,
  onDataLoaded,
  ...props
}: PartslogicProductListWrapperProps) => {
  const [selectedFitment, setSelectedFitment] = React.useState<
    FitmentSelectorWrapperProps['selectedValues']
  >(selectedVinFitment || null);
  const [fitmentLabels, setFitmentLabels] = React.useState<
    FitmentLabelEntity[]
  >([]);
  const [fitmentValues, setFitmentValues] =
    React.useState<FitmentSelectorProps['labelsData']>();
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    // We need to wait to the list of labels to know the labels order and set proper values for each label
    const fitmentStr = getExistingFitment();
    if (!initailSelectedValues && fitmentLabels.length && fitmentStr) {
      setSelectedFitment(getSelectedFitment(fitmentLabels, fitmentStr));
    } else if (initailSelectedValues) {
      setSelectedFitment(initailSelectedValues);
    }
    if (fitmentLabels.length) {
      setReady(true);
    }
  }, [fitmentLabels, initailSelectedValues, selectedVinFitment]);

  const _onDataLoaded = (labels, optionalLabels, data) => {
    if (labels && data) {
      setFitmentLabels(labels);
      setFitmentValues(data);
    }
    onDataLoaded?.(labels, optionalLabels, data);
  };

  const _onSubmit = (values, opts) => {
    onSubmit?.(
      convertSelectedDataToArray(fitmentLabels, values, fitmentValues)
        .map((item) => item.value)
        .join('|'),
      opts
    );
  };

  return (
    <FitmentSelectorWrapper
      key={JSON.stringify(selectedFitment)}
      autocommitDelay={autocommitDelay}
      className={classnames({ [styles.hide]: !ready }, className)}
      clearButtonText={clearButtonText}
      filter={filter}
      orientation={orientation}
      searchButtonText={searchButtonText}
      selectedValues={selectedFitment}
      onDataLoaded={_onDataLoaded}
      styled={styled}
      fitmentLoaded={ready}
      onSubmit={_onSubmit}
      {...props}
    ></FitmentSelectorWrapper>
  );
};

export default ParslogicFitmentSelectorWrapper;

export function getSelectedFitment(fitmentLabels, initialSelectedFitment) {
  if (fitmentLabels.length && initialSelectedFitment) {
    const selectedFitmentValues = initialSelectedFitment.split('|');
    const selectedFitmentParsed = fitmentLabels.reduce(
      (acc, label, index) => ({
        ...acc,
        [label.name]: selectedFitmentValues[index],
      }),
      {}
    );
    return selectedFitmentParsed;
  }
}

function convertSelectedDataToArray(
  labels: FitmentLabelEntity[],
  values: SelectedValues,
  labelValues: FitmentSelectorProps['labelsData']
) {
  const parsedValues = [];
  for (const label of labels) {
    if (values[label.name] && labelValues[label.name]) {
      const selectedValueName = labelValues[label.name].find(
        (item) => item.id === values[label.name]
      )?.name;

      parsedValues.push({
        key: label.name,
        value: selectedValueName as string | number,
      });
    }
  }

  return parsedValues;
}
