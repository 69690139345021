import debounce from 'lodash/debounce';
import * as React from 'react';
import useFetch from '../../common/customHooks/useFetch';
import { DEFAULT_VISIBLE_FIELDS } from '../../common/ProductListItem/ProductListItem';
import { getProductsQuery, PRODUCTS_URL } from '../../utils/queryUtils';
import ProductList from '../ProductList/ProductList';
import { ProductListResponse, ProductListWrapperProps } from './models';
import styles from './styles/listWrapper.scss';

const ProductListWrapper = ({
  className = '',
  styled,
  orientation = 'horizontal',
  search,
  itemsPerPage = 5,
  currentPage: page = 1,
  showPagination = false,
  onPageChanged = () => null,
  onDataReceived = () => null,
  showControls = false,
  showLoadingIndicator = false,
  visibleFields = DEFAULT_VISIBLE_FIELDS,
  renderList,
  renderNoResults: renderNoResultsCustom,
  query,
  linkEl,
  onItemsPerPageChange,
  onItemClick,
  onLayoutChange,
  noImageUrl,
  onSortChange,
  sortOptions,
  selectedSort,
  productId,
  enableAddToCartButton = false,
  onAddToCart,
  addToCartButtonText,
  validateShowAddToCartButton,
  extraSortOptions,
}: ProductListWrapperProps) => {
  const [loadData, state] = useFetch<ProductListResponse>();
  const [currentPage, setCurrentPage] = React.useState(page);
  const [itemsPerPageInternal, setItemsPerPage] = React.useState(itemsPerPage);
  const [selectedSortInternal, setSelectedSortInternal] =
    React.useState(selectedSort);

  const wasRendered = React.useRef(false);

  /*
      Debounce function to prevent multiple calls when user is in a different page than
      the first one and then changes the search string.
      When that happens the effect that depends on search and the effect that depends on
      page will both call loadData function almost at the same time, this happens only when
      user is sending the page as param.
    */
  const _loadData = React.useCallback(
    debounce(
      (
        _page: number,
        _itemsPerPage: number,
        _search: string,
        sort: string | number,
        _query
      ) => {
        loadData(`${PRODUCTS_URL}`, {
          ..._query,
          ...getProductsQuery(
            _page,
            _itemsPerPage,
            _search,
            sort,
            productId as string
          ),
        });
      },
      1 // 1 is just to wait until the call stack is empty to prevent multiple calls at the same time
    ),
    []
  );

  React.useEffect(() => {
    if (itemsPerPageInternal !== itemsPerPage) {
      setItemsPerPage(itemsPerPage);
    }
  }, [itemsPerPage]);

  React.useEffect(() => {
    if (selectedSort !== selectedSortInternal) {
      setSelectedSortInternal(selectedSort);
    }
  }, [selectedSort]);

  // This effect should run only when a query string that affects the current page has changed.
  // We only want to set the current page to 1 in this scenario.
  React.useEffect(() => {
    let pageToQuery = currentPage;
    // This conditional is to prevent calling the API on first render,
    // the hook that listen for page changes will make the first call.
    if (wasRendered.current) {
      setCurrentPage(1);
      onPageChanged(1);
      pageToQuery = 1;

      _loadData(
        pageToQuery,
        itemsPerPageInternal,
        search,
        selectedSortInternal,
        query
      );
    }
    wasRendered.current = true;
  }, [search, query, itemsPerPageInternal, selectedSortInternal]);

  React.useEffect(() => {
    setCurrentPage(page);

    _loadData(page, itemsPerPageInternal, search, selectedSortInternal, query);
  }, [page]);

  React.useEffect(() => {
    if (state.status === 'RESOLVED') {
      onDataReceived(state.data);
    }
  }, [state.data, state.status]);

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage);
    onPageChanged(newPage);
    _loadData(
      newPage,
      itemsPerPageInternal,
      search,
      selectedSortInternal,
      query
    );
  };

  const renderNoResults = () => {
    switch (state.status) {
      case 'RESOLVED':
        return renderNoResultsCustom ? (
          renderNoResultsCustom()
        ) : (
          <h4 className={styles.noResultsText}>
            No results for current search
          </h4>
        );
      case 'REJECTED':
        return <h4 className={styles.noResultsText}>{state.error}</h4>;
      default:
        break;
    }

    return null;
  };

  const list = state.data?.list || [];
  // theres one or more exact match present on the product search result
  const exactMatchInResult = state.data?.exactMatchInResult || false;
  // this is the customer configuration on database if they enable it
  const exactPartMatch = state.data?.exactPartMatch || false;
  const showSimilarPartNumberMatch =
    state.data?.showSimilarPartNumberMatch || false;
  const totalPages = Math.ceil((state.data?.total || 0) / itemsPerPageInternal);
  const isBusy = state.status === 'LOADING';

  return (
    <div className={className}>
      <ProductList
        styled={styled}
        orientation={orientation}
        showPagination={showPagination}
        onPageChanged={onChangePage}
        showLoadingIndicator={showLoadingIndicator}
        visibleFields={visibleFields}
        renderList={renderList}
        producListMetaData={{
          exactMatchInResult,
          exactPartMatch,
          showSimilarPartNumberMatch,
        }}
        renderNoResults={renderNoResults}
        list={list}
        totalPages={totalPages}
        currentPage={currentPage}
        isLoading={isBusy}
        linkEl={linkEl}
        showControls={showControls}
        itemsPerPage={itemsPerPageInternal}
        onItemsPerPageChange={(value) => {
          setItemsPerPage(value);
          onItemsPerPageChange?.(value);
        }}
        onItemClick={onItemClick}
        onLayoutChange={onLayoutChange}
        noImageUrl={noImageUrl}
        onSortChange={(data) => {
          setSelectedSortInternal(data);
          onSortChange?.(data);
        }}
        sortOptions={sortOptions}
        selectedSort={selectedSortInternal}
        enableAddToCartButton={enableAddToCartButton}
        onAddToCart={onAddToCart}
        addToCartButtonText={addToCartButtonText}
        validateShowAddToCartButton={validateShowAddToCartButton}
        extraSortOptions={extraSortOptions}
      />
    </div>
  );
};

export default ProductListWrapper;
