import React from 'react';

import { getConfig } from '../../config';
import LoggedInLoader from './LoggedIn';
import LoggedOutLoader from './LoggedOut';

export type StoredFitmentLoaderProps = {
  buttonLabel?: string;
  limit?: number;
  reload?: boolean;
  reloadDelay?: number;
  searchPrefix?: string;
};

// eslint-disable-next-line complexity
const StoredFitmentLoader = (props: StoredFitmentLoaderProps) => {
  const { API_KEY, UID } = getConfig();

  if (!API_KEY) {
    return;
  }

  if (UID) return <LoggedInLoader {...props} />;

  return <LoggedOutLoader {...props} />;
};

export default StoredFitmentLoader;
