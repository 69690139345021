import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { isEmpty, isEqual } from 'lodash';

import {
  FITMENT_SELECTOR_STORAGE_KEY,
  HISTORIC_FITMENT_STORAGE_KEY,
} from '../../utils/constants';
import { env, getConfig } from '../../config';
import useFetch from '../../common/customHooks/useFetch';
import { UserFitmentsLogResponse } from '../../models/search';
import { ProductListResponse } from '../ProductListWrapper/models';
import { SelectedValues } from '../FitmentSelector/models';
import CrossIcon from '../../icons/CrossIcon';
import restFactory from '../../utils/restFactory';
import styles from './StoredFitmentLoader.scss';
import { StoredFitmentLoaderProps } from './StoredFitmentLoader';
import {
  isLoading,
  formatObjectKeysToUpperCase,
  isObjectInArray,
} from './utils';
import { fitmentToString } from '../../utils/fitmentUtils';

const FITMENT_LOG_URL = `${env.API_URL}/user-fitment-log`;

const DEFAULT_BUTTON_LABEL = 'My Garage';
const DEFAULT_HISTORIC_FITMENT_LIMIT = 10;

const updateComponentFitmentQueryParams = (
  searchPrefix: string,
  fitmentValue: any,
  reload?: boolean,
  reloadDelay?: number
) => {
  const searchParams = new URLSearchParams(window.location.search);
  const url = window.location.pathname + searchPrefix;

  if (isEmpty(fitmentValue)) {
    searchParams.delete('fitment');
  } else {
    searchParams.set('fitment', Object.values(fitmentValue).join('|'));
  }
  const newUrl = searchParams.values
    ? `${url}?${searchParams.toString()}`
    : url;
  window.history.replaceState({}, '', newUrl);
  if (reload) {
    setTimeout(() => {
      location.reload();
    }, reloadDelay || 0);
  }
};

// Delete selected fitment
const useDeleteFitment = () => {
  const { API_KEY, UID } = getConfig();

  const [deletedFitment, setDeletedFitment] = useState('');
  const [deletionError, setDeletionError] = useState(null);

  const deleteFitment = async (fitment: string) => {
    setDeletedFitment('');
    isLoading(true);
    try {
      const deleteUrl = new URL(`${FITMENT_LOG_URL}/${UID}/${API_KEY}`);
      deleteUrl.searchParams.set('fitment', fitment);
      const response = await fetch(deleteUrl, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`Error deleting item: ${response.statusText}`);
      }
    } catch (error) {
      setDeletionError(error);
      isLoading(false);
    } finally {
      setDeletedFitment(fitment);
      isLoading(false);
    }
  };

  return { deleteFitment, deletedFitment, deletionError };
};

// eslint-disable-next-line complexity
const LoggedInLoader = ({
  searchPrefix = '',
  buttonLabel = DEFAULT_BUTTON_LABEL,
  limit = DEFAULT_HISTORIC_FITMENT_LIMIT,
  reload,
  reloadDelay,
}: StoredFitmentLoaderProps) => {
  const { API_KEY, UID } = getConfig();
  const [loadFitmentData, fitmentState] = useFetch<UserFitmentsLogResponse>();
  const { deleteFitment, deletedFitment } = useDeleteFitment();
  const [selectedFitment, setSelectedFitment] = useState({});
  const [showHistoricFitment, setShowHistoricFitment] = useState(false);
  const [storedFitmentValues, setStoredFitmentValues] = React.useState<
    SelectedValues[]
  >([]);
  const [selectedFitmentLabel, setSelectedFitmentLabel] = useState(null);
  const [historicFitmentLimit, setHistoricFitmentLimit] = useState(limit);

  // Updates localStorage from search
  React.useEffect(() => {
    const updateFitment = (event: CustomEvent) => {
      const newFitment = formatObjectKeysToUpperCase(event.detail.fitment);
      const historicFitment = JSON.parse(
        localStorage.getItem(HISTORIC_FITMENT_STORAGE_KEY)
      );
      const isIncluded = isObjectInArray(newFitment, historicFitment);

      if (!isIncluded) {
        addStoredValues(newFitment);
      } else if (isEmpty(newFitment)) {
        setSelectedFitmentLabel('');
      } else {
        const storedSelectedFitment = JSON.parse(
          localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY ?? '[]')
        )?.[0];
        setSelectedFitmentLabel(storedSelectedFitment?.split('|').join(' '));
      }
    };

    window.addEventListener('PL_FITMENT_CHANGED', updateFitment);
    return () => {
      window.removeEventListener('PL_FITMENT_CHANGED', updateFitment);
    };
  }, []);

  // Updates fitment limit from products response
  React.useEffect(() => {
    const updateFitment = (e: CustomEvent) =>
      e.detail.limit && setHistoricFitmentLimit(e.detail.limit);
    window.addEventListener('PL_HISTORIC_FITMENT_LIMIT', updateFitment);

    return () => {
      window.removeEventListener('PL_HISTORIC_FITMENT_LIMIT', updateFitment);
    };
  }, []);

  // Request latest stored fitments. It will be triggered also after removing one
  useEffect(() => {
    if (UID && API_KEY && fitmentState?.status !== 'LOADING') {
      loadFitmentData(`${FITMENT_LOG_URL}/${UID}/${API_KEY}`, {});
    }
  }, [deletedFitment]);

  // Updates storedFitmentValues from request response
  useEffect(() => {
    if (fitmentState.status === 'RESOLVED') {
      const { data } = fitmentState;
      const fitmentData = data as unknown as [];
      const currentFitments: string[] | [] =
        (fitmentData.length > 0 && fitmentState.data[0].fitments) || [];

      const formattedFitments = currentFitments.map((item) => {
        const [Year, Make, Model] = item.split('|');
        // eslint-disable-next-line sort-keys
        return { Year, Make, Model };
      });
      if (formattedFitments !== storedFitmentValues) {
        setStoredFitmentValues(formattedFitments);
        localStorage.setItem(
          HISTORIC_FITMENT_STORAGE_KEY,
          JSON.stringify(formattedFitments)
        );
      }
    }
  }, [fitmentState.status]);

  // Format selected fitment
  useEffect(() => {
    const selectedFitment = JSON.parse(
      localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY ?? '[]')
    )?.[0];

    setSelectedFitmentLabel(
      selectedFitment ? selectedFitment.split('|').join(' ') : ''
    );
  }, [selectedFitment]);

  // Add new fitment value
  const addStoredValues = (newValues: SelectedValues) => {
    // eslint-disable-next-line max-len
    const currentList = storedFitmentValues.length
      ? storedFitmentValues
      : JSON.parse(localStorage.getItem(HISTORIC_FITMENT_STORAGE_KEY));
    const fitmentAlreadySelected = currentList.some((current) => {
      const keys = Object.keys(current);
      return keys.every(
        (key) =>
          current[key]?.toString().toLowerCase() ===
          newValues[key]?.toString().toLowerCase()
      );
    });
    if (!fitmentAlreadySelected && !isEmpty(newValues)) {
      currentList.push(newValues);
      const updatedValues: SelectedValues[] = currentList;
      if (currentList.length > historicFitmentLimit) {
        currentList.shift();
      }
      localStorage.setItem(
        HISTORIC_FITMENT_STORAGE_KEY,
        JSON.stringify(updatedValues)
      );
      setStoredFitmentValues(
        JSON.parse(localStorage.getItem(HISTORIC_FITMENT_STORAGE_KEY))
      );
    }
    if (!Object.keys(newValues).length) {
      setSelectedFitmentLabel('');
    } else {
      const storedSelectedFitment = JSON.parse(
        localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY ?? '[]')
      )?.[0];
      setSelectedFitmentLabel(storedSelectedFitment?.split('|').join(' '));
    }
  };

  // Remove selected value
  const removeSelectedFitmentValue = async () => {
    localStorage.removeItem(FITMENT_SELECTOR_STORAGE_KEY);
    const fitmentEvent = new CustomEvent('PL_FITMENT_CHANGED', {
      detail: { fitment: {} },
    });
    updateComponentFitmentQueryParams(searchPrefix, {}, reload, reloadDelay);
    window.dispatchEvent(fitmentEvent);
    setSelectedFitment({});
  };

  // Calls useDeleteFitment to remove fitment from stored ones
  const removeStoredValues = async (values: SelectedValues) => {
    const selectedFitment = Object.values(values).join('|');
    if (UID && API_KEY && selectedFitment) {
      await deleteFitment(selectedFitment);
      if (
        selectedFitment ===
        JSON.parse(
          localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY ?? '[]')
        )?.[0]
      ) {
        removeSelectedFitmentValue();
      }
    }
  };

  const handleHistoricFitment = () => {
    setShowHistoricFitment(!showHistoricFitment);
  };

  const handleSubmit = async (fitment: SelectedValues) => {
    try {
      isLoading(true);
      await restFactory.get<ProductListResponse>(`${env.API_URL}/products`, {
        fitment: fitmentToString(fitment),
        limit: 1,
        page: 1,
      });
    } catch (error) {
      console.warn({ message: `Error fetching fitment data - ${error}` });
      isLoading(false);
    } finally {
      const fitmentEvent = new CustomEvent('PL_FITMENT_CHANGED', {
        detail: { fitment: fitment, source: 'mygarage' },
      });
      // Update query param
      updateComponentFitmentQueryParams(
        searchPrefix,
        fitment,
        reload,
        reloadDelay
      );
      // Dispatch fitment changed event
      window.dispatchEvent(fitmentEvent);
      // Add new fitment to historic list
      addStoredValues(fitment);
      // Update localStorage current fitment
      localStorage.setItem(
        FITMENT_SELECTOR_STORAGE_KEY,
        `["${fitmentToString(fitment)}"]`
      );
      // Update selected fitment
      setSelectedFitment(fitment);
      isLoading(false);
    }
  };

  if (!API_KEY || !storedFitmentValues.length) {
    return;
  }

  return (
    <div className={classnames(styles['container'])}>
      <button onClick={handleHistoricFitment}>{buttonLabel}</button>
      <label className={classnames(styles['selected-historic-fitment'])}>
        {selectedFitmentLabel ? (
          <>
            {selectedFitmentLabel}
            <button
              className={classnames(styles['delete-button'])}
              onClick={() => removeSelectedFitmentValue()}
            >
              <CrossIcon className={classnames(styles['cross-icon'])} />
            </button>
          </>
        ) : null}
      </label>
      {showHistoricFitment &&
      Object.keys(storedFitmentValues || {}).length > 0 ? (
        <div className={classnames(styles['content'])}>
          {storedFitmentValues.slice(0, limit).map((fitment, index) => {
            const result = Object.keys(fitment).map((key, index) => {
              return (
                <span key={`${key}-${index}`}>
                  <b>
                    {String(key).charAt(0).toUpperCase() + String(key).slice(1)}
                  </b>
                  : {fitment[key]}{' '}
                </span>
              );
            }, '');

            const selectedFitment = JSON.parse(
              localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY ?? '[]')
            )?.[0];
            const isSelected = isEqual(
              fitmentToString(fitment),
              selectedFitment
            );

            return (
              // eslint-disable-next-line react/jsx-key
              <div key={index} className={classnames(styles['actions'])}>
                <button
                  className={classnames(
                    styles['action-button'],
                    isSelected ? styles['selected-fitment'] : ''
                  )}
                  onClick={() => handleSubmit(fitment)}
                >
                  <p>{result}</p>
                </button>
                <button
                  className={classnames(styles['delete-button'])}
                  onClick={() => removeStoredValues(fitment)}
                >
                  <CrossIcon className={classnames(styles['cross-icon'])} />
                </button>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default LoggedInLoader;
